/* global instantsearch */

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { doc, getDoc, setDoc, getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCrqWblMxrDD2V9Qet_IsFVmtykhY2pHdQ',
  authDomain: 'shall-seek.firebaseapp.com',
  projectId: 'shall-seek',
  storageBucket: 'shall-seek.appspot.com',
  messagingSenderId: '789690716040',
  appId: '1:789690716040:web:6d0637aa42981452fab43d',
  measurementId: 'G-0T7KT2WYYT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const provider = new GoogleAuthProvider();

console.log('auth');

const db = getFirestore(app);

const storePlayState = async ({
  userId,
  pdfUrl,
  audioUrl,
  title,
  currentTime = null,
}) => {
  // Add a new document in collection "cities"
  const res = await setDoc(doc(db, userId, 'currentTrack'), {
    title,
    pdfUrl,
    audioUrl,
    currentTime,
  });

  console.log(res);
};

const getPlayState = async (userId) => {
  const docRef = doc(db, userId, 'currentTrack');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log('Document data:', docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!');
  }
};

const getAndStorePlayState = async () => {
  const title = document.getElementById('audio-source').textContent;
  const userId = getUserId();
  const pdfUrl = document.getElementById('main-pdf').href;
  const audioUrl = audioPlayer.currentSrc;
  const currentTime = audioPlayer.currentTime;

  console.log({ title, userId, pdfUrl, audioUrl, currentTime });
  storePlayState({ title, userId, pdfUrl, audioUrl, currentTime });
};

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from 'firebase/auth';

const auth = getAuth();

const signIn = () => {
  return signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;

      console.log(user);
      console.log(auth.currentUser.displayName);
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      console.log(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};

auth.onAuthStateChanged((user) => {
  if (user) {
    console.log({ user });

    console.log(auth.currentUser.uid);

    getPlayState(auth.currentUser.uid).then((data) => {
      console.log(data);
      updateAudioPlayer(data);

      document.getElementById('sign-in').classList.add('invisible');
    });
  } else {
    console.log('not user');
    // signInWithPopup(auth, provider)
    //   .then((result) => {
    //     // This gives you a Google Access Token. You can use it to access the Google API.
    //     const credential = GoogleAuthProvider.credentialFromResult(result);
    //     const token = credential.accessToken;
    //     // The signed-in user info.
    //     const user = result.user;

    //     console.log(user);
    //     console.log(auth.currentUser.displayName);
    //     // ...
    //   })
    //   .catch((error) => {
    //     // Handle Errors here.
    //     console.log(error);
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     // The email of the user's account used.
    //     const email = error.customData.email;
    //     // The AuthCredential type that was used.
    //     const credential = GoogleAuthProvider.credentialFromError(error);
    //     // ...
    //   });
  }
});

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: 'xyz', // Be sure to use an API key that only allows searches, in production
    nodes: [
      {
        host: 'search.isometry.co',
        port: '443',
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  //  filterBy is managed and  overridde n by InstantSearch.js. To set it, you want to use one of the filter widgets like refinementList or use the `configure` widget.
  additionalSearchParameters: {
    query_by: 'transcript',
    sort_by: 'lineNum:asc',
  },
});

// signOut(auth)
//   .then(() => {
//     // Sign-out successful.
//   })
//   .catch((error) => {
//     // An error happened.
//   });

const searchClient = typesenseInstantsearchAdapter.searchClient;

const search = instantsearch({
  searchClient,
  indexName: 'sermons',
});

search.addWidgets([
  instantsearch.widgets.searchBox({
    container: '#searchbox',
    placeholder: '📖 Search Sermon Transcripts',
  }),
  instantsearch.widgets.configure({
    hitsPerPage: 8,
  }),
  instantsearch.widgets.hits({
    container: '#hits',
    templates: {
      item(item) {
        return `
        <div>
          <div class="hit-authors">
          ${item._highlightResult.transcript.value}
          </div>
          <div class="hit-name">
          <a style="text-decoration:none;" target="_blank" href="${item._highlightResult.pdf_url.value}">📃 ${item._highlightResult.title.value}</a>

        </div>
        <div class="hit-authors">
        ${item._highlightResult.timestamp.value}
        </div>
        <button onclick="updateAudioPlayer({play:true, title: '${item._highlightResult.title.value}',pdfUrl: '${item._highlightResult.pdf_url.value}',audioUrl:'${item._highlightResult.audio_url.value}#t=${item._highlightResult.timestamp.value}'})">Play</button>

        </div>
      `;
      },
    },
  }),
  instantsearch.widgets.refinementList({
    container: '#refinement-list',
    attribute: 'title',
    searchable: true,
    showMore: true,
    showMoreLimit: 150,
    limit: 10,
    searchablePlaceholder: '📚 Search Sermon Titles',
  }),
  instantsearch.widgets.stats({
    container: '#stats',
  }),
  instantsearch.widgets.pagination({
    container: '#pagination',
  }),
]);

search.start();

function updateAudioPlayer({
  audioUrl,
  title,
  pdfUrl,
  play = false,
  currentTime = false,
}) {
  console.log(audioUrl);
  var audio = document.getElementById('audio-player');
  var audioTitle = document.getElementById('audio-source');
  var audioLink = document.getElementById('main-audio');
  var pdfLink = document.getElementById('main-pdf');
  audioTitle.href = pdfUrl;
  audioTitle.innerText = title;
  audio.src = audioUrl;
  audioLink.href = audioUrl;
  pdfLink.href = pdfUrl;
  if (currentTime) {
    audio.currentTime = currentTime;
  }
  if (play) {
    audio.play();
  }
}

// updateAudioPlayer({
//   audioUrl: 'https://youtubom',
//   title: 'Hello',
//   pdfUrl: 'olkjsd',
// });

const getUserId = () => {
  return auth.currentUser.uid;
};
window.updateAudioPlayer = updateAudioPlayer;

window.storePlayState = storePlayState;

window.getPlayState = getPlayState;

window.getAndStorePlayState = getAndStorePlayState;

window.getUserId = getUserId;

window.signIn = signIn;
